self.deprecationWorkflow = self.deprecationWorkflow || {};
self.deprecationWorkflow.config = {
  workflow: [
    { handler: 'throw', matchId: 'ember.component.reopen' },
    { handler: 'throw', matchId: 'ember-source.deprecation-without-for' },
    { handler: 'throw', matchId: 'ember-source.deprecation-without-since' },
    { handler: 'throw', matchId: 'ember-app-scheduler.setupRouter' },
    {
      handler: 'throw',
      matchId: 'deprecated-run-loop-and-computed-dot-access',
    },
    {
      handler: 'throw',
      matchId: 'ember-simple-auth.mixins.authenticated-route-mixin',
    },
    {
      handler: 'throw',
      matchId: 'ember-simple-auth.mixins.data-adapter-mixin',
    },
    {
      handler: 'throw',
      matchId: 'ember-simple-auth.mixins.unauthenticated-route-mixin',
    },
    { handler: 'throw', matchId: 'this-property-fallback' },
    { handler: 'throw', matchId: 'implicit-injections' },
    { handler: 'throw', matchId: 'ember-metal.get-with-default' },
    {
      handler: 'throw',
      matchId: 'ember.built-in-components.legacy-arguments',
    },
    {
      handler: 'throw',
      matchId: 'ember.built-in-components.legacy-attribute-arguments',
    },
    { handler: 'throw', matchId: 'ember-modifier.no-args-property' },
    { handler: 'throw', matchId: 'ember-modifier.no-element-property' },
    { handler: 'throw', matchId: 'ember-modifier.use-modify' },
    { handler: 'throw', matchId: 'ember-data:model.toJSON' },
    { handler: 'silence', matchId: 'ember-global' },
    { handler: 'silence', matchId: 'ember-utils.try-invoke' },
    // Ember 4 deprecations
    { handler: 'silence', matchId:'ember-polyfills.deprecate-assign'},
    { handler: 'silence', matchId:'remove-owner-inject'}
  ],
};
